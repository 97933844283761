import request from './request'

const api = {
	// 列表
	lists: '/Posts/index',
	// 新增
	add: '/Posts/create',
	// 删除
	dele: '/Posts/delete',
	// 地区详情
	read: '/Posts/read',
	// 编辑
	edit: '/Posts/update',
}

export {
	request,
	api
}
